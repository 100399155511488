/* eslint-disable import/no-cycle */
import {IWidgetController} from '@wix/native-components-infra/dist/src/types/types';
import {CurrencyService} from '../../services/CurrencyService';
import {CurrencyConverterStore} from './currencyConverterStore';
import {IContext, ICurrencyConverterWidgetControllerConfig} from './createViewerScript';
import {APP_DEFINITION_ID, WIDGET_ID} from '../constants';

export function createAppController(
  controllerConfig: ICurrencyConverterWidgetControllerConfig,
  context: IContext,
  reportError
): IWidgetController {
  const {setProps} = controllerConfig;

  return {
    pageReady() {
      const {siteStore} = context;
      const fedopsLoggerFactory = siteStore.platformServices.fedOpsLoggerFactory;
      const fedopsLogger = fedopsLoggerFactory.getLoggerForWidget({
        appId: APP_DEFINITION_ID,
        widgetId: WIDGET_ID,
      });
      const currencyService = new CurrencyService(
        siteStore.httpClient,
        siteStore.resolveAbsoluteUrl.bind(siteStore),
        fedopsLogger
      );
      const currencyConverterStore = new CurrencyConverterStore({
        siteStore,
        currencyService,
        updateComponent: setProps,
        reportError,
      });

      return currencyConverterStore.setInitialState(reportError);
    },
  };
}
