export interface CurrencySymbols {
  [code: string]: string;
}

const getCurrencyWithSymbolIfExists = (code, symbol) => (symbol ? `${code} (${symbol})` : code);
export const GetCurrencyDisplayedValue = (code: string, codeSymbols: {[code: string]: string}, defaultText?: string) =>
  code && codeSymbols ? getCurrencyWithSymbolIfExists(code, codeSymbols[code]) : defaultText;

export const CurrencySymbolsMap = (currencySymbols) =>
  currencySymbols.reduce((acu, symbol) => {
    acu[symbol.code] = symbol.symbol;
    return acu;
  }, {}) as CurrencySymbols;
