import {Currency} from '@wix/ambassador-currency-converter/http';
import {SetCurrenciesRequest, SiteCurrency} from '@wix/ambassador-currency-site-settings/http';
import {IFedOpsLogger} from '@wix/native-components-infra/dist/src/types/types';
import {FedopsLogger} from '@wix/wixstores-client-core/dist/es/src/types/external-types';
import {WixStoresHttp} from '@wix/wixstores-client-core/dist/src/http/http';
import {FedopsInteractions} from '../settings/constants/fedopsInteractions';

export class CurrencyService {
  constructor(
    private readonly httpClient: WixStoresHttp,
    private readonly resolvePath: (path: string) => string = (path) => path,
    private readonly fedopsLogger: IFedOpsLogger | FedopsLogger
  ) {}

  public async getAvailableCurrenciesList(): Promise<Currency[]> {
    this.fedopsLogger.interactionStarted(FedopsInteractions.GET_AVAILABLE_CURRENCIES);
    const currencies = ((await this.httpClient.get(this.resolvePath(`/_api/currency-converter/v1/currencies`))) as any)
      .currencies;
    this.fedopsLogger.interactionEnded(FedopsInteractions.GET_AVAILABLE_CURRENCIES);
    return currencies;
  }

  public async getCurrencies(): Promise<SiteCurrency[]> {
    this.fedopsLogger.interactionStarted(FedopsInteractions.GET_CURRENCIES);

    const currencies = (
      (await this.httpClient.get(this.resolvePath(`/_api/currency-converter-settings/v1/currencies/site`))) as any
    ).currencies;
    this.fedopsLogger.interactionEnded(FedopsInteractions.GET_CURRENCIES);
    return currencies;
  }

  public async setCurrencies(currencies: SetCurrenciesRequest): Promise<void> {
    this.fedopsLogger.interactionStarted(FedopsInteractions.SET_CURRENCIES);
    await this.httpClient.put(this.resolvePath(`/_api/currency-converter-settings/v1/currencies/site`), currencies);
    return this.fedopsLogger.interactionEnded(FedopsInteractions.SET_CURRENCIES);
  }
}
